import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  // ROUTES ADMIN
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminDashboard.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/admin-produk',
    name: 'Admin Produk',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminProduk.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/admin-monitoring',
    name: 'Admin Monitoring',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminMonitoring.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/admin-spmb',
    name: 'Admin SPMB',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminSPMB.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/admin-spmb-detail',
    name: 'Admin SPMB Detail',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminSPMBDetail.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/pesan-barang',
    name: 'Pesan Barang',
    component: () => import( /* webpackChunkName: "about" */ '../views/RequestProduct.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminTransactions.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/transaction/:id',
    name: 'Transactions Status',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminTransactionsStatus.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/detail-transaction',
    name: 'Transaction Detail',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminDetailTransaction.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/cek-stok',
    name: 'Cek Stok',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminCekStok.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/stok-masuk',
    name: 'Stok Masuk',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminStokMasuk.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/stok-keluar',
    name: 'Stok Keluar',
    component: () => import( /* webpackChunkName: "about" */ '../views/AdminStokKeluar.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/master-category',
    name: 'Master Category',
    component: () => import( /* webpackChunkName: "about" */ '../views/MasterCategory.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/master-product-name',
    name: 'Master Product Name',
    component: () => import( /* webpackChunkName: "about" */ '../views/MasterProductName.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/master-type',
    name: 'Master Type',
    component: () => import( /* webpackChunkName: "about" */ '../views/MasterType.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/master-satuan',
    name: 'Master Satuan',
    component: () => import( /* webpackChunkName: "about" */ '../views/MasterUnit.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/master-jabatan',
    name: 'Master Jabatan',
    component: () => import( /* webpackChunkName: "about" */ '../views/MasterPosition.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/master-bidang',
    name: 'Master Bidang',
    component: () => import( /* webpackChunkName: "about" */ '../views/MasterDivision.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/master-seksi',
    name: 'Master Seksi',
    component: () => import( /* webpackChunkName: "about" */ '../views/MasterSection.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/master-user',
    name: 'Master User',
    component: () => import( /* webpackChunkName: "about" */ '../views/MasterUser.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/cetak-bon',
    name: 'Bon',
    component: () => import( /* webpackChunkName: "about" */ '../views/Bon.vue'),
    meta: {
      auth: true
    }
  },
  // PEGAWAI
  {
    path: '/my-order',
    name: 'My Order',
    component: () => import( /* webpackChunkName: "about" */ '../views/PegawaiTransactions.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/my-profile',
    name: 'My Profile',
    component: () => import( /* webpackChunkName: "about" */ '../views/PegawaiProfile.vue'),
    meta: {
      auth: true
    }
  },
  // 
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import( /* webpackChunkName: "about" */ '../views/Categories.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import( /* webpackChunkName: "about" */ '../views/Products.vue')
  },
  {
    path: '/category/:slug',
    name: 'category',
    component: () => import( /* webpackChunkName: "about" */ '../views/Category.vue')
  },
  {
    path: '/product/:slug',
    name: 'product',
    component: () => import( /* webpackChunkName: "about" */ '../views/Product.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// tambahkan ini untuk melakukan pengecekan pada setiap routing
router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some(record => record.meta.auth)) {
    // jika user adalah guest
    if (store.getters['auth/guest']) {
      // tampilkan pesan bahwa harus login dulu
      store.dispatch('alert/set', {
        status: true,
        text: 'Login first',
        color: 'error',
      })
      // tampilkan form login
      store.dispatch('dialog/setComponent', 'login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router