import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/helper';
import ReadMore from 'vue-read-more';
import {
  LoadingPlugin
} from "vuetify-loading-overlay"
import VueApexCharts from 'vue-apexcharts'
import JsonExcel from "vue-json-excel";
import lineClamp from 'vue-line-clamp'

Vue.use(lineClamp, {
  // plugin options
})
 
Vue.component("downloadExcel", JsonExcel);
Vue.use(LoadingPlugin, {
  //props
  spinnerProps: {},
  overlayProps: {},
});

// APEXCHART
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// READMORE
Vue.use(ReadMore);

// VUE MOMENT
const moment = require('moment')
require('moment/locale/id')

Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')