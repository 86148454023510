export default {
    namespaced: true,
    state: {
        carts: [],
    },
    mutations: {
        insert: (state, payload) => {
            state.carts.push({
                id: payload.id,
                product_code: payload.product_code,
                barcode: payload.barcode,
                category_id: payload.category_id,
                type_id: payload.type_id,
                product_name_id: payload.product_name_id,
                brand: payload.brand,
                stock: payload.stock,
                image: payload.image,
                description: payload.description,
                views_count: payload.views_count,
                updated_at: payload.updated_at,
                created_at: payload.created_at,
                product_name: payload.product_name,
                type: payload.type,
                category: payload.category,
                desc: payload.desc,
                product_id: payload.product_id,
                unit: payload.unit,
                quantity: 1
            })
        },
        update: (state, payload) => {
            let idx = state.carts.indexOf(payload);
            state.carts.splice(idx, 1, {
                id: payload.id,
                product_code: payload.product_code,
                barcode: payload.barcode,
                category_id: payload.category_id,
                type_id: payload.type_id,
                product_name_id: payload.product_name_id,
                brand: payload.brand,
                stock: payload.stock,
                image: payload.image,
                description: payload.description,
                views_count: payload.views_count,
                updated_at: payload.updated_at,
                created_at: payload.created_at,
                product_name: payload.product_name,
                type: payload.type,
                category: payload.category,
                desc: payload.desc,
                product_id: payload.product_id,
                unit: payload.unit,
                quantity: payload.quantity
            });
            if (payload.quantity <= 0) {
                state.carts.splice(idx, 1)
            }
        },
        // batch update carts
        set: (state, payload) => {
            state.carts = payload
        },
    },
    actions: {
        add: ({
            state,
            commit
        }, payload) => {
            let cartItem = state.carts.find(item => item.product_id === payload.product_id)
            if (!cartItem) {
                commit('insert', payload)
            } else {
                cartItem.quantity++
                commit('update', cartItem)
            }
        },
        // menghapus cart pada item tertentu
        remove: ({
            state,
            commit
        }, payload) => {
            let cartItem = state.carts.find(item => item.product_id === payload.product_id)
            if (cartItem) {
                cartItem.quantity--
                commit('update', cartItem)
            }
        },
        // batch update carts
        set: ({
            commit
        }, payload) => {
            commit('set', payload)
        },

    },
    getters: {
        carts: state => state.carts,
        count: (state) => {
            return state.carts.length
        },
        // menghitung total harga
        // totalPrice: (state) => {
        //     let total = 0
        //     state.carts.forEach(function (cart) {
        //         total += cart.price * cart.quantity
        //     })
        //     return total
        // },
        // total jumlah barang
        totalQuantity: (state) => {
            let total = 0
            state.carts.forEach(function (cart) {
                total += cart.quantity
            })
            return total
        },
        // total berat barang
        // totalWeight: (state) => {
        //     let total = 0
        //     state.carts.forEach(function (cart) {
        //         total += cart.weight
        //     })
        //     return total
        // },

    }
}