<template>
  <div>
    <v-container class="mt-2" grid-list-sm>
      <div class="text-left pb-3 pl-14">
        <v-hover v-slot="{ hover }">
          <v-btn
            small
            to="/categories"
            color="info"
            class="pa-6 px-12"
            :elevation="hover ? 10 : 2"
            :class="{ 'on-hover': hover }"
          >
            <v-icon left>mdi-clipboard-text</v-icon>Kategori</v-btn
          >
        </v-hover>
      </div>
      <v-sheet class="mx-auto" max-width="100%">
        <v-slide-group v-model="model" active-class="success" show-arrows>
          <v-slide-item
            v-for="category in categories"
            :key="`category-` + category.id"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :to="'/category/' + category.id"
                class="ma-1"
                :elevation="hover ? 10 : 2"
                :class="{ 'on-hover': hover }"
              >
                <v-card-title
                  ><v-avatar size="35">
                    <v-img
                      :src="getIcon(category.icon)"
                      style="width: 100%"
                    ></v-img>
                  </v-avatar>
                  &nbsp; <small>{{ category.category }}</small></v-card-title
                >
              </v-card>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <!-- template categries -->
      <!-- <v-container class="ma-0 pa-0" grid-list-sm>
        <div class="text-right">
          <v-btn small text to="/categories" class="blue--text">
            All Categories <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-row align="center">
          <v-col
            cols="12"
            sm="6"
            md="3"
            v-for="category in categories"
            :key="`category-` + category.id"
          >
            <v-card :to="'/category/' + category.id">
              <v-card-title
                class="fill-height align-end"
                v-text="category.category"
              ></v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container> -->
    </v-container>
    <!-- template populer produk -->
    <v-container class="mt-4" grid-list-sm>
      <div class="pb-2 d-flex justify-space-between">
        <div>
          <span style="font-size: 15pt"
            ><strong>Produk terpopuler</strong></span
          >
        </div>
        <div>
          <v-btn small text to="/products" class="blue--text">
            Lihat Semua <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-layout wrap>
        <v-flex
          v-for="product in productsPopuler"
          :key="`product-` + product.product_id"
          xs6
          sm6
          md3
          lg3
          class="pa-1 pa-md-2 pa-lg-2"
        >
          <book-item :product="product" />
        </v-flex>
      </v-layout>
      <!-- <v-row align="center">
        <v-col
          cols="6"
          sm="6"
          md="3"
          v-for="product in productsPopuler"
          :key="`product-` + product.product_id"
        >
          <book-item :product="product" />
        </v-col>
      </v-row>  --> </v-container
    ><br />
    <!-- template terbaru produk -->
    <v-container class="mt-2" grid-list-sm>
      <div class="pb-2 d-flex justify-space-between">
        <div>
          <span style="font-size: 15pt"><strong>Produk terbaru</strong></span>
        </div>
        <div>
          <v-btn small text to="/products" class="blue--text">
            Lihat Semua <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-layout wrap>
        <v-flex
          v-for="product in productsLatest"
          :key="`product-` + product.product_id"
          xs6
          sm6
          md3
          lg3
          class="pa-1 pa-md-2 pa-lg-2"
        >
          <book-item :product="product" />
        </v-flex>
      </v-layout>
      <!-- <v-row align="center">
        <v-col
          cols="6"
          sm="6"
          md="3"
          v-for="product in productsLatest"
          :key="`product-` + product.product_id"
        >
          <book-item :product="product" />
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    BookItem: () =>
      import(/* webpackChunkName: "book-item" */ "@/components/BookItem.vue"),
  },
  data: () => ({
    model: null,
    categories: [],
    productsPopuler: [],
    productsLatest: [],
  }),
  created() {
    // console.log("get data categories");
    this.axios
      .get("/all_category") // <== jadi ginis
      .then((response) => {
        let data = response.data;
        this.categories = data; // <== ini gaes
        // console.log(this.categories);
      })
      .catch((error) => {
        let { responses } = error;
        console.log(responses);
      });

    this.axios
      .get("/products_popular")
      .then((response) => {
        let { data } = response.data;
        this.productsPopuler = data;
      })
      .catch((error) => {
        let { responses } = error;
        console.log(responses);
      });

    this.axios
      .get("/products_latest")
      .then((response) => {
        let { data } = response.data;
        this.productsLatest = data;
      })
      .catch((error) => {
        let { responses } = error;
        console.log(responses);
      });
  },
};
</script>
