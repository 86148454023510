<template>
  <v-app>
    <v-app-bar app color="primary" dark extended v-if="isHome">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img src="/img/logo/lalampa-navbarfix.png" max-width="200px"></v-img>
      <v-toolbar-title to="/">
        <strong
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
            appNameDetail
          }}</strong
        >
      </v-toolbar-title>
      <!-- pemisah konten -->
      <v-spacer></v-spacer>
      <v-btn icon @click="setDialogComponent('cart')">
        <v-badge color="orange" overlap v-if="countCart > 0">
          <template v-slot:badge
            ><span>{{ countCart }}</span></template
          >
          <v-icon>mdi-cart</v-icon>
        </v-badge>
        <v-icon v-else>mdi-cart</v-icon>
      </v-btn>
      <v-text-field
        slot="extension"
        hide-details
        solo-inverted
        flat
        label="Search"
        prepend-inner-icon="mdi-magnify"
        @click="setDialogComponent('search')"
      ></v-text-field>
    </v-app-bar>
    <v-app-bar app color="primary" dark v-else>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img src="/img/logo/lalampa-navbarfix.png" max-width="200px"></v-img>
      <v-toolbar-title to="/">
        <strong
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
            appNameDetail
          }}</strong
        >
      </v-toolbar-title>
      <!-- pemisah konten -->
      <v-spacer></v-spacer>
      <v-btn icon @click="setDialogComponent('cart')">
        <v-badge color="orange" overlap v-if="countCart > 0">
          <template v-slot:badge
            ><span>{{ countCart }}</span></template
          >
          <v-icon>mdi-cart</v-icon>
        </v-badge>
        <v-icon v-else>mdi-cart</v-icon>
      </v-btn>
    </v-app-bar>

    <!--  color="#FCEC27" -->
    <v-navigation-drawer app v-model="drawer">
      <!-- Logo GUEST -->
      <v-container v-if="guest">
        <v-fade-transition mode="out-in">
          <v-row class="mt-2">
            <v-col cols="12" align="center">
              <!-- <v-img src="/img/logo/logopajak.png" style="width: 100%"></v-img> -->
              <v-img
                src="/img/logo/lalampa-sidebarfix.png"
                style="width: 65%"
              ></v-img>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-container>
      <!-- AVATAR USER -->
      <v-container style="width: 40%" v-if="!guest">
        <v-fade-transition mode="out-in">
          <v-row>
            <v-col cols="12">
              <v-avatar size="80">
                <v-img
                  :src="getAvatar(user.data.photo)"
                  style="width: 100%"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-container>
      <!-- NAMA GUEST -->
      <div class="text-center mb-2" style="color: #02275d" v-if="guest">
        <!-- <b>{{ appName }}</b
        ><br /><small>{{ appNameDetail }}</small> -->
      </div>
      <!-- NAMA USER -->
      <div class="text-center mb-2" style="color: #02275d" v-if="!guest">
        <b>{{ user.data.full_name }}</b
        ><br /><small>{{ user.data.division_name }}</small>
      </div>

      <div class="pa-2" v-if="guest">
        <v-btn
          block
          color="primary"
          class="mb-1"
          @click="setDialogComponent('login')"
        >
          <v-icon left>mdi-lock</v-icon>
          Login
        </v-btn>
        <v-btn block color="#FCEC27" @click="setDialogComponent('register')">
          <v-icon left>mdi-account</v-icon>
          Register
        </v-btn>
      </div>

      <v-divider></v-divider>

      <!-- BELUM LOGIN -->
      <v-list v-if="guest">
        <v-list-item
          v-for="(item, index) in menus"
          :key="`menu-` + index"
          :to="item.route"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon left>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- SUDAH LOGIN -->
      <div v-if="!guest">
        <!-- ROLE 1 ADMIN -->
        <v-list v-if="this.user.data.role == '1'">
          <!-- Menu 1 -->
          <v-list-item
            v-for="(item, index) in menuAdmin"
            :key="`menu-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Transaksi -->
          <v-list-group
            v-for="item in itemsTransaksi"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content class="ml-2">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.route"
            >
              <v-list-item-content class="ml-2">
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- Stok -->
          <v-list-group
            v-for="item in itemsStok"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content class="ml-2">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.route"
            >
              <v-list-item-content class="ml-2">
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- Menu 2 -->
          <v-list-item
            v-for="(item, index) in menuAdmin2"
            :key="`menu2-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Data Master -->
          <v-list-group
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content class="ml-2">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.route"
            >
              <v-list-item-content class="ml-2">
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>

        <!-- ROLE SELAIN ADMIN -->
        <v-list v-else>
          <v-list-item
            v-for="(item, index) in menuPegawai"
            :key="`menu-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <template v-slot:append v-if="!guest">
        <div class="pa-2">
          <v-btn block color="red" dark @click="logout">
            <v-icon left>mdi-lock</v-icon>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <alert />

    <keep-alive>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialogbottom-transition"
      >
        <component :is="currentComponent" @closed="setDialogStatus"></component>
      </v-dialog>
    </keep-alive>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer absolute app>
      <v-card-text class="text-center">
        &copy; {{ new Date().getFullYear() }} —
        <strong
          >Kantor Wilayah DJP Sulawesi Utara, Tengah, Gorontalo, dan Maluku
          Utara</strong
        >
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Alert: () =>
      import(/* webpackChunkName: "alert" */ "@/components/Alert.vue"),
    Search: () =>
      import(/* webpackChunkName: "search" */ "@/components/Search.vue"),
    Login: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    Register: () =>
      import(/* webpackChunkName: "login" */ "@/views/Register.vue"),
    Cart: () => import(/* webpackChunkName: "cart" */ "@/components/Cart.vue"),
  },
  data: () => ({
    drawer: false,
    menus: [
      { title: "Home", icon: "mdi-home", route: "/" },
      // { title: "About", icon: "mdi-account", route: "/about" },
    ],

    menuAdmin: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "Dashboard", icon: "mdi-view-dashboard", route: "/dashboard" },
      { title: "Produk", icon: "mdi-cube-scan", route: "/admin-produk" },
      { title: "Pesan Barang", icon: "mdi-basket-fill", route: "/pesan-barang" },
      // { title: "Transaksi", icon: "mdi-shopping", route: "/transactions" },
    ],

    menuAdmin2: [
      {
        title: "Monitoring",
        icon: "mdi-table-of-contents",
        route: "/admin-monitoring",
      },
      {
        title: "SPMB",
        icon: "mdi-clipboard-text-play-outline",
        route: "/admin-spmb",
      },
      { title: "Users", icon: "mdi-account-group", route: "/master-user" },
      { title: "My Profile", icon: "mdi-account", route: "/my-profile" },
    ],

    menuPegawai: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "My Order", icon: "mdi-shopping", route: "/my-order" },
      { title: "Pesan Barang", icon: "mdi-basket-fill", route: "/pesan-barang" },
      { title: "My Profile", icon: "mdi-account", route: "/my-profile" },
      // { title: "Transaksi", icon: "mdi-shopping", route: "/transactions" },
    ],

    items: [
      {
        action: "mdi-database",
        items: [
          { title: "Kategori", route: "/master-category" },
          { title: "Nama Produk", route: "/master-product-name" },
          { title: "Tipe Produk", route: "/master-type" },
          { title: "Satuan Produk", route: "/master-satuan" },
          { title: "Jabatan", route: "/master-jabatan" },
          { title: "Bidang", route: "/master-bidang" },
          { title: "Seksi", route: "/master-seksi" },
        ],
        title: "Data Master",
      },
    ],

    itemsTransaksi: [
      {
        action: "mdi-shopping",
        items: [
          { title: "Semua", route: "/transactions" },
          { title: "Belum Diproses", route: "/transaction/1" },
          { title: "Sementara Diproses", route: "/transaction/2" },
          { title: "Proses Pengantaran", route: "/transaction/3" },
          { title: "Selesai", route: "/transaction/4" },
          { title: "Dibatalkan", route: "/transaction/5" },
        ],
        title: "Transaksi",
      },
    ],

    itemsStok: [
      {
        action: "mdi-archive",
        items: [
          { title: "Cek Stok", route: "/cek-stok" },
          { title: "Stok Masuk", route: "/stok-masuk" },
          { title: "Barang Reject", route: "/stok-keluar" },
        ],
        title: "Stok",
      },
    ],
  }),
  methods: {
    ...mapActions({
      setDialogStatus: "dialog/setStatus",
      setDialogComponent: "dialog/setComponent",
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),
    logout() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .post("/auth/logout", {}, config)
        .then(() => {
          this.$vloading.hide();
          this.setAuth({}); // kosongkan auth ketika logout
          this.setAlert({
            status: true,
            color: "success",
            text: "Logout successfully",
          });
          this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          this.$vloading.hide();
          let { data } = error.response;
          console.log(data.code);
          if (data.code == 500) {
            this.setAuth({}); // kosongkan auth ketika logout
            this.setAlert({
              status: true,
              color: "success",
              text: "Logout successfully",
            });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: data.message,
            });
          }
        });
    },
  },
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    ...mapGetters({
      countCart: "cart/count",
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },
};
</script>
